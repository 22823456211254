import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, NavParams } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { GewebeService } from './gewebe.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ManagefavoritenService {
  DataArray: Array<string> = [];
  favs?: any = null;
  _temp?: any = [];

  checkedItems: number[] = [];

  fetching: boolean = false;

  constructor(
    private storage: Storage,
    public navCtrl: NavController,
    public router: Router,
    public gewebeService: GewebeService,
    public translate: TranslateService
  ) {}

  updateFavs() {
    this.storage.get('favoriten').then((val) => {
      this.favs = val;
    });
  }

  addFavorite(newfav: number) {
    var vorhanden: boolean = false;
    var next: number = 0;

    this.storage
      .get('favoriten')
      .then((val) => {
        this.DataArray = val;
        if (this.DataArray === null) {
          let temp: Array<string> = [newfav.toString()];
          this.storage.set('favoriten', temp);
          return;
        }
        if (this.DataArray.indexOf(newfav.toString()) !== -1) {
          // Schon vorhanden
        } else {
          this.DataArray.push(newfav.toString());
          var favoritestar = document.getElementById('favoritestar');
          favoritestar.style.display = 'none';
        }
        this.storage.set('favoriten', this.DataArray);
        this.favs = this.DataArray;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  checkFavorite(newfav: number) {
    var vorhanden: boolean = false;
    var next: number = 0;
    var favsChecked: any[];

    this.storage
      .get('favoriten')
      .then((val) => {
        this.DataArray = val;
        if (this.DataArray === null) {
          return 0;
        }
        if (this.DataArray.indexOf(newfav.toString()) !== -1) {
          return 1;
        } else {
          return 0;
        }
        return 2;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  async emptyFavorite() {
    let temp: Array<string> = [];
    await this.storage.set('favoriten', temp);
    this.favs = temp;
    this.countFavs();
    this.router.navigate(['/home/']);
  }

  ngOnInit() {
    this.countFavs();
  }

  countFavs() {
    this.storage.get('favoriten').then((val) => {
      this.favs = val;
    });
  }

  async toggleChecked(id: number) {
    let found = this.checkedItems.find((i) => {
      return i == id;
    });

    if (found === undefined) {
      this.checkedItems.push(id);
    } else {
      this.checkedItems.splice(this.checkedItems.indexOf(found), 1);
    }
  }

  async sendFavorite() {
    let language = await this.storage.get('lang');

    this._temp = [];
    this.storage.get('favoriten').then(async (val) => {
      this.favs = val;

      // Every Favorite
      for (let e = 0; e < val.length; e++) {
        this.gewebeService.items.subscribe(
          async (result) => {
            // Find our Item
            let myTemp = result.items.find((i) => {
              return i.uid === val[e];
            });

            if (
              this.checkedItems.find((i) => {
                return i == myTemp.uid;
              }) === undefined
            ) {
              return;
            }

            switch (language) {
              case 'en':
                myTemp['waermeschutz_extern_title'] = 'External summer heat protection';
                myTemp['waermeschutz_intern_title'] = 'Internal summer heat protection';
                myTemp['blendschutz_title'] = 'Glare protection';
                myTemp['sichtschutz_title'] = 'Privacy by night';
                myTemp['sichtkontakt_title'] = 'View to the outside';
                myTemp['transmission_title'] = 'Transmission % (TS)';
                myTemp['reflexion_title'] = 'Reflection % (RS)';
                myTemp['absorption_title'] = 'Absorption % (AS)';
                myTemp['fc_wert_title'] = 'F<sub>c</sub>';
                myTemp['g_tot_wert_title'] = 'g<sub>tot</sub>';
                myTemp['uv_schutzfaktor_title'] = 'UV protection factor (USF factor)';
                myTemp['max_ballenbreite_title'] = 'Bolt width in mm';
                myTemp['tv_nn_title'] = 'Tv, n-n';
                myTemp['bezeichnung'] = myTemp['bezeichnung_text_en'];
                myTemp['translatedFreitext'] = myTemp['freitext_en'];
                this.translate.use('en');

                break;
              case 'fr':
                myTemp['waermeschutz_extern_title'] =
                  'Protection thermique à l’extérieur, période estivale (protection solaire extérieure)';
                myTemp['waermeschutz_intern_title'] =
                  'Protection thermique à l’intérieur, période estivale (protection solaire intérieure)';
                myTemp['blendschutz_title'] = 'Protection contre l’éblouissement';
                myTemp['sichtschutz_title'] = 'Intimité de nuit';
                myTemp['sichtkontakt_title'] = 'Contact visuel avec l';
                myTemp['transmission_title'] = 'Transmission % (TS)';
                myTemp['reflexion_title'] = 'Réflexion % (RS)';
                myTemp['absorption_title'] = 'Absorption % (AS)';
                myTemp['fc_wert_title'] = 'Valeur FC';
                myTemp['g_tot_wert_title'] = 'Facteur g<sub>tot</sub>';
                myTemp['uv_schutzfaktor_title'] = 'Facteur de protection UV UPF';
                myTemp['max_ballenbreite_title'] = 'Largeur de bande en mm';
                myTemp['tv_nn_title'] = 'Tv, n-n';
                myTemp['bezeichnung'] = myTemp['bezeichnung_text_fr'];
                myTemp['translatedFreitext'] = myTemp['freitext_fr'];
                this.translate.use('fr');

                break;
              case 'nl':
                myTemp['waermeschutz_extern_title'] =
                  'Zomerse, thermische beveiliging openlucht (Zonbescherming openlucht)';
                myTemp['waermeschutz_intern_title'] = 'Zomerse, thermische beveiliging intern (binnen-zonwering)';
                myTemp['blendschutz_title'] = 'Verblindingsbescherming';
                myTemp['sichtschutz_title'] = "Doorzichtbescherming naar binnen 's nachts";
                myTemp['sichtkontakt_title'] = 'Doozicht naar buiten';
                myTemp['transmission_title'] = 'Transmissie % (TS)';
                myTemp['reflexion_title'] = 'Reflectie % (RS)';
                myTemp['absorption_title'] = 'Absorptie % (AS)';
                myTemp['fc_wert_title'] = 'F<sub>c</sub>';
                myTemp['g_tot_wert_title'] = 'g<sub>tot</sub>';
                myTemp['uv_schutzfaktor_title'] = 'UV-beschermfactor USF';
                myTemp['max_ballenbreite_title'] = 'Balbreedte in mm';
                myTemp['tv_nn_title'] = 'Tv, n-n';
                myTemp['bezeichnung'] = myTemp['bezeichnung_text_nl'];
                myTemp['translatedFreitext'] = myTemp['freitext_nl'];
                this.translate.use('nl');

                break;
              default:
                myTemp['waermeschutz_extern_title'] = 'Sommerlicher Wärmeschutz extern';
                myTemp['waermeschutz_intern_title'] = 'Sommerlicher Wärmeschutz intern';
                myTemp['blendschutz_title'] = 'Blendschutz';
                myTemp['sichtschutz_title'] = 'Sichtschutz bei Nacht';
                myTemp['sichtkontakt_title'] = 'Sichtkontakt nach außen';
                myTemp['transmission_title'] = 'Transmission % (TS)';
                myTemp['reflexion_title'] = 'Reflexion % (RS)';
                myTemp['absorption_title'] = 'Absorption % (AS)';
                myTemp['fc_wert_title'] = 'F<sub>c</sub>';
                myTemp['g_tot_wert_title'] = 'g<sub>tot</sub>';
                myTemp['uv_schutzfaktor_title'] = 'UV-Schutzfaktor (USF-Faktor)';
                myTemp['max_ballenbreite_title'] = 'Ballenbreite in mm';
                myTemp['tv_nn_title'] = 'Tv, n-n';
                myTemp['bezeichnung'] = myTemp['bezeichnung_text'];
                myTemp['translatedFreitext'] = myTemp['freitext'];
                this.translate.use('de');

                break;
            }

            myTemp['description_title'] = this.translate.instant('BESCHREIBUNG');
            myTemp['description'] = this.translate.instant('DESCRIPTION');
            myTemp['titel'] = this.translate.instant('PDF_TITLE');
            myTemp['copyright'] = this.translate.instant('COPYRIGHT');

            myTemp['label_front'] = this.translate.instant('SONNENSEITE');
            myTemp['label_back'] = this.translate.instant('INNENSEITE');
            myTemp['label_3'] = this.translate.instant('SICHTKONTAKT');
            myTemp['label_4'] = this.translate.instant('SICHTSCHUTZ');

            if (myTemp.seite == 0) {
              myTemp['front_active'] = 'active';
              myTemp['back_active'] = 'inactive';
              myTemp['seite'] = this.translate.instant('OBERSEITE');
            } else {
              myTemp['front_active'] = 'inactive';
              myTemp['back_active'] = 'active';
              myTemp['seite'] = this.translate.instant('UNTERSEITE');
            }

            this._temp.push(myTemp);
          },
          (error) => {
            console.error('Error creating Data');
          },
          () => {
            if (e == val.length - 1) {
              this.generatePDF();
            }
          }
        );
      }
    });
  }

  async generatePDF() {
    if (this._temp.length == 0) {
      return;
    }

    // Set the fetching var to true (to show that the site is loading)
    this.fetching = true;

    const rawResponse = await fetch('https://yp2dcko5oc.execute-api.eu-central-1.amazonaws.com/dev/pdf', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this._temp),
    });
    const content = await rawResponse.blob();
    this.showFile(content);
  }

  showFile(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = 'file.pdf';
    link.click();

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);

    // Set the fetching var to false (Loading done)
    this.fetching = false;
  }
}

export class FavInfo {
  favorite: number;
}
