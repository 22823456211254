import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'detailview/:myid', loadChildren: './detailview/detailview.module#DetailviewPageModule' },
  { path: 'about', loadChildren: './about/about.module#AboutPageModule' },
  { path: 'favoriten', loadChildren: './favoriten/favoriten.module#FavoritenPageModule' },
  { path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule) },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
