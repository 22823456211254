import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { GewebeService } from '../gewebe.service';
import { TranslateService } from '@ngx-translate/core';
import { faTrash, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-filtermodal',
  templateUrl: './filtermodal.component.html',
  styleUrls: ['./filtermodal.component.scss'],
})
export class FiltermodalComponent implements OnInit {
  public expertOn: boolean;
  public moreGewebe: boolean;
  public elementbreite: boolean;
  public sichtschutz: any = 0;
  public sichtkontakt: any = 0;
  public items: any;
  

  private farbschemaSaved = '';

  public myOptions = {
    placement: 'left',
    'show-delay': 500,
    width: '80%',
    'max-width': 700,
    'content-type': 'html',
    'tooltip-class': 'hinweis',
  };

  faTrash = faTrash;
  faTimesCircle = faTimesCircle;

  constructor(
    public alertController: AlertController,
    public gewebeservice: GewebeService,
    public translate: TranslateService,
    public viewCtrl: ModalController,
    private storage: Storage
  ) {
    this.moreGewebe = this.gewebeservice.moreGewebe;
    this.elementbreite = this.gewebeservice.elementbreite;
  }

  startFilter(farbschema?: string) {
    let farbe = farbschema;
    this.farbschemaSaved = farbschema;
    this.items = this.gewebeservice.setFilter(farbschema);
  }

  resetFilter() {
    this.items = this.gewebeservice.resetFilter();
    console.log('Filter wird zurückgesetzt');
  }

  closeFilter() {
    this.viewCtrl.dismiss();
  }

  toggleMoreGewebe() {
    this.gewebeservice.moreGewebe = this.moreGewebe;
  }

  toggleElementbreite(){
    console.log("Elementbreite" + this.elementbreite);
    this.gewebeservice.elementbreite = this.elementbreite;
  }

  async toggleExpert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('EXPERTENMODUS'),
      message: this.translate.instant('EXPERTENMODUSHINWEIS'),
      buttons: [
        {
          text: this.translate.instant('OK'),
          handler: () => {
            if (this.expertOn === true) {
              this.expertOn = false;
              this.storage.set('expertOn', false);
            } else {
              this.expertOn = true;
              this.storage.set('expertOn', true);
            }
          },
        },
        {
          text: this.translate.instant('ABBRECHEN'),
          handler: () => {
            console.log('Cancel');
          },
        },
      ],
    });

    await alert.present();
  }

  ngOnInit() {

      this.storage.get('expertOn').then((value) => {
        
        
      });

    this.startFilter();
  }
}
