import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GewebeService {
  public items: any = [];
  public favItems: any = [];

  public searchTerm: string = '';

  public moreGewebe: boolean = false;

  public elementbreite: boolean = false;

  // Filter Variablen
  public sichtschutz: any = 0;
  public sichtkontakt: any = 0;
  public waermeschutz_extern: any = 0;
  public blendschutz: any = 0;

  // Expertenmodus
  public fc: any = 0;
  public gtot: any = 0;
  public reflexion: any = 0;
  public transmission: any = 0;
  public absorption: any = 0;
  public uvschutzfaktor: any = 0;
  public waermeschutz_intern: any = 0;
  public max_ballenbreite: any = 0;
  public max_elementbreite: any = 0;

  // Farbfilter
  public farbschema: any = 0;

  // Filter Maxvalues
  public sichtschutz_max: number = 4;
  public sichtkontakt_max: number = 4;
  public waermeschutz_extern_max: number = 4;
  public blendschutz_max: number = 4;

  public fc_max: number = 0.37;
  public gtot_max: number = 0.22;
  public transmission_max: number = 32;
  public reflexion_max: number = 80;
  public absorption_max: number = 97;
  public uvschutzfaktor_max: number = 100;
  public waermeschutz_intern_max: number = 2;
  public max_ballenbreite_max: number = 3500;
  public max_elementbreite_max: number = 4000;

  // Filter widths
  public sichtschutz_width: number = 100;
  public sichtkontakt_width: number = 100;
  public waermeschutz_extern_width: number = 100;
  public blendschutz_width: number = 100;

  public fc_width: number = 100;
  public gtot_width: number = 100;
  public transmission_width: number = 100;
  public reflexion_width: number = 100;
  public absorption_width: number = 100;
  public uvschutzfaktor_width: number = 100;
  public waermeschutz_intern_width: number = 100;
  public max_ballenbreite_width: number = 100;

  constructor(public http: HttpClient) {
    this.getData();
  }

  getData() {
    let url = '//romagewebe.agile-projektgruppe.de/gewebe.php';
    let data: Observable<any> = this.http.get(url);
    this.items = data;
    this.items.subscribe((d) => {
      // console.log(d);
      // console.log(d.items.find(i => i.artikelnummer == "4850770"));
    });
    this.favItems = data;
  }

  getGewebe() {
    return this.items;
  }

  filterItems(searchTerm) {
    return;
  }

  setFilter(farbe?: string) {
    if (farbe) {
      this.farbschema = farbe;
    }
    return;
  }

  resetFilter() {
    // Update Issue #58 Reset searchbar when reseting filter
    this.searchTerm = '';

    this.farbschema = 0;
    this.sichtschutz = 0;
    this.sichtkontakt = 0;
    this.waermeschutz_extern = 0;
    this.blendschutz = 0;

    // Expertenfilter
    this.fc = 0;
    this.gtot = 0;
    this.reflexion = 0;
    this.transmission = 0;
    this.absorption = 0;
    this.uvschutzfaktor = 0;
    this.waermeschutz_intern = 0;
    this.max_ballenbreite = 0;

    // Max Values
    this.sichtschutz_max = 4;
    this.sichtkontakt_max = 4;
    this.waermeschutz_extern_max = 4;
    this.blendschutz_max = 4;

    this.fc_max = 0.75;
    this.gtot_max = 0.22;
    this.transmission_max = 32;
    this.reflexion_max = 80;
    this.absorption_max = 97;
    this.uvschutzfaktor_max = 100;
    this.waermeschutz_intern_max = 2;
    this.max_ballenbreite_max = 3500;
  }
}
