import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, Events } from '@ionic/angular';
import { FiltermodalComponent } from './filtermodal/filtermodal.component';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ManagefavoritenService } from './managefavoriten.service';
import { GewebeService } from './gewebe.service';

import { Storage } from '@ionic/storage';
import { faSlidersH, faTrash, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  faFilter = faSlidersH;
  faTrash = faTrash;
  faTimesCircle = faTimesCircle;

  public myOptions = {
    placement: 'left',
    'show-delay': 500,
    width: '80%',
    'max-width': 700,
    'content-type': 'html',
    'tooltip-class': 'hinweis',
  };

  lang: any;
  sumFavs = 0;
  public searchTerm = '';
  public searchControl: FormControl;
  public items: any;
  public imageurl = '//d6kasyire3mnd.cloudfront.net/';
  public modal: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public managefavoritenService: ManagefavoritenService,
    public translate: TranslateService,
    public gewebeservice: GewebeService,
    public modalController: ModalController,
    private storage: Storage,
    public navCtrl: NavController,
    public router: Router,
    public events: Events
  ) {
    this.initializeApp();
    this.sumFavs = 23;

    this.lang = 'en';
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  async presentModal() {
    this.modal = await this.modalController.create({
      component: FiltermodalComponent,
      cssClass: 'my-custom-modal-css',
    });
    return await this.modal.present();
  }

  async closeModal() {
    return await this.modal.dismiss();
  }

  ngOnInit() {
    this.setFilteredItems();

    this.managefavoritenService.updateFavs();
    setTimeout(() => {
      // somecode
      this.storage.get('lang').then((storedlang) => {
        if (storedlang) {
          this.lang = storedlang;
          this.translate.use(storedlang);
        } else {
          this.lang = 'de';
          this.translate.use(this.lang);
        }
        this.translate.use(this.lang);

        this.lang = this.lang;
        this.storage.set('lang', this.lang);
        // this.gewebeservice.items.bezeichnung_text = this.gewebeservice.items.bezeichnung_text_fr;

        this.events.publish('lang:changed', this.lang);
      });
    }, 1000);
  }

  setFilteredItems() {
    this.items = this.gewebeservice.filterItems(this.searchTerm);
  }

  ionViewWillEnter() {
    this.managefavoritenService.countFavs();
  }

  ionViewDidEnter() {}

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  switchLanguage(newlang: string) {
    this.translate.use(newlang);

    this.lang = newlang;
    this.storage.set('lang', newlang);
    // this.gewebeservice.items.bezeichnung_text = this.gewebeservice.items.bezeichnung_text_fr;

    this.events.publish('lang:changed', newlang);
  }

  startFilter(farbschema?: string) {
    this.items = this.gewebeservice.setFilter(farbschema);
  }

  logScrollStart() {
    console.log('logScrollStart : When Scroll Starts');
  }

  resetFilter() {
    this.items = this.gewebeservice.resetFilter();
  }
}
