import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-imagemodal',
  templateUrl: './imagemodal.component.html',
  styleUrls: ['./imagemodal.component.scss'],
})
export class ImagemodalComponent implements AfterViewInit {
  @Input('pic1') pic1;
  @Input('pic2') pic2;
  @Input('pic3') pic3;
  @Input('pic4') pic4;

  @Input('picUrl1') picUrl1;
  @Input('picUrl2') picUrl2;
  @Input('picUrl3') picUrl3;
  @Input('picUrl4') picUrl4;

  @Input('activeIndex') activeIndex;

  @ViewChild('slider', { static: false }) slider: ElementRef;
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  posX1 = 0;
  posX2 = 0;
  posInitial;
  posFinal;
  slides: HTMLCollectionOf<Element>;
  threshold = 100;
  slideSize = 1000;
  slidesLength = 0;
  firstSlide: Node;
  lastSlide: Node;
  cloneFirst: Node;
  cloneLast: Node;
  index = 0;
  allowShift = true;

  constructor(private rd: Renderer2, public viewCtrl: ModalController) {}

  ngAfterViewInit() {
    console.log(this.activeIndex);

    if (this.pic1 != '0') {
      this.slidesLength++;
    }
    if (this.pic2 != '0') {
      this.slidesLength++;
    }
    if (this.pic3 != '0') {
      this.slidesLength++;
    }
    if (this.pic4 != '0') {
      this.slidesLength++;
    }

    this.slides = document.getElementsByClassName('slide');

    setTimeout(() => {
      this.slideSize = this.wrapper.nativeElement.offsetWidth;
      this.slider.nativeElement.style.left = -this.slideSize * (this.activeIndex + 1) + 'px';
    }, 1);

    this.firstSlide = this.slides[0];
    this.lastSlide = this.slides[this.slides.length - 1];

    this.cloneFirst = this.firstSlide.cloneNode(true);
    this.cloneLast = this.lastSlide.cloneNode(true);

    this.slider.nativeElement.appendChild(this.cloneFirst);
    this.slider.nativeElement.insertBefore(this.cloneLast, this.firstSlide);
  }

  closeModal() {
    this.viewCtrl.dismiss();
  }

  onResize(e) {
    this.slideSize = this.wrapper.nativeElement.offsetWidth;
    this.slider.nativeElement.style.left = this.posInitial + this.slideSize + 'px';
  }

  dragStart(e) {
    e = e || window.event;
    e.preventDefault();
    this.posInitial = this.slider.nativeElement.offsetLeft;

    if (e.type == 'touchstart') {
      this.posX1 = e.touches[0].clientX;
    } else {
      this.posX1 = e.clientX;
      document.onmouseup = (e) => {
        this.dragEnd(e);
      };
      document.onmousemove = (e) => {
        this.dragAction(e);
      };
    }
  }

  dragAction(e) {
    e = e || window.event;

    if (e.type == 'touchmove') {
      this.posX2 = this.posX1 - e.touches[0].clientX;
      this.posX1 = e.touches[0].clientX;
    } else {
      this.posX2 = this.posX1 - e.clientX;
      this.posX1 = e.clientX;
    }
    this.slider.nativeElement.style.left = this.slider.nativeElement.offsetLeft - this.posX2 + 'px';
  }

  dragEnd(e) {
    this.posFinal = this.slider.nativeElement.offsetLeft;
    if (this.posFinal - this.posInitial < -this.threshold) {
      this.shiftSlide(1, 'drag');
    } else if (this.posFinal - this.posInitial > this.threshold) {
      this.shiftSlide(-1, 'drag');
    } else {
      this.slider.nativeElement.style.left = this.posInitial + 'px';
    }

    document.onmouseup = null;
    document.onmousemove = null;
  }

  shiftSlide(dir, action = undefined) {
    this.slider.nativeElement.classList.add('shifting');

    if (this.allowShift) {
      if (!action) {
        this.posInitial = this.slider.nativeElement.offsetLeft;
      }

      if (dir == 1) {
        this.slider.nativeElement.style.left = this.posInitial - this.slideSize + 'px';
        this.index++;
      } else if (dir == -1) {
        this.slider.nativeElement.style.left = this.posInitial + this.slideSize + 'px';
        this.index--;
      }
    }

    this.allowShift = false;
  }

  checkIndex() {
    this.slider.nativeElement.classList.remove('shifting');

    if (this.index == -1) {
      this.slider.nativeElement.style.left = -(this.slidesLength * this.slideSize) + 'px';
      this.index = this.slidesLength - 1;
    }

    if (this.index == this.slidesLength) {
      this.slider.nativeElement.style.left = -(1 * this.slideSize) + 'px';
      this.index = 0;
    }

    this.allowShift = true;
  }
}
